import React, { Component } from "react";
import logo from "../../img/logoNew.png";
import "./signup.css";

export default class Terms extends Component {
  render() {
    return (
      <div>
        <section className="login">
          <div className="sing_log1">
            <div className="sing_one1 mb-4">
              <img src={logo} alt="logo" style={{width:"120px"}}/>
            </div>
            <div className="step-forms step-forms-active">
              <div className="text_sing1">
                <h4 className="Privacy">Terms & Conditions</h4>
                <h4 className="justify-content-end textHead">
                  Terms & Conditions
                </h4>
                <p className="justify-content-end textPara">
                This website and application is owned by ESG Reporting Intelligence Pty Ltd (ACN 658 428 623). By accessing this website and/or using any of our Services accessible though the website, you agree to be bound by our terms and conditions of use. If you do not agree to be bound by our terms and conditions, please do not use the website or our services. 
                </p>
                <br />
                <h4 className="justify-content-end textHead">
                  Changes to these Terms
                </h4>
                <p className="justify-content-end textPara">
                  We reserve the right, in our sole discretion, to change,
                  modify, add or remove portions of these terms and conditions.
                  Your continued use of the website following the posting of
                  changes will mean that you accept and agree to the changes.
                  Please check our website regularly for updates.
                </p>
                <br />
                <h4 className="justify-content-end textHead">Content</h4>
                <p className="justify-content-end textPara">
                  All text, graphics, user interfaces, visual interfaces,
                  photographs, trademarks (whether registered or not), logos,
                  sounds, artwork of any kind, and computer code (collectively,
                  “Content”), including but not limited to the design,
                  structure, coordination, selection, appearance, and
                  arrangement of the Content, contained on the Website is owned
                  by ESG Reporting Intelligence and is protected by copyright,
                  trademark, and other property laws and unfair competition
                  laws. You must not misuse any of the Content and ESG Reporting
                  Intelligence will do all that is lawful to enforce and protect
                  this Content.
                  <br />
                  <br />
                  <h5 className="justify-content-end textHead">
                    1. Defined terms
                  </h5>
                  <p className="justify-content-end textPara">
                    All references to “ESG Reporting Intelligence”. “ESG
                    Reporting Intelligence”, “we”, “us” or “our” means ESG
                    Reporting Intelligence Pty Ltd ACN 639 381 347. “Services”
                    means the services provided ESG Reporting Intelligence as
                    set out in clause 5.
                  </p>
                  <br />
                  <h5 className="justify-content-end textHead">
                    2. Use of Website, Application and Service
                  </h5>
                  <p className="justify-content-end textPara">
                    As a user of the Website or a user registered to use any of
                    the Services (a “Registered User”), you agree to the
                    following:
                  </p>
                </p>
                <br />
                <p className="justify-content-end textPara">
                  <b>(a) Exclusive Use.</b> Your account will only be used by
                  you and only for your business needs. You must not authorise
                  any other person to use your account, and you must not assign
                  or otherwise transfer your account to any other person or
                  entity. Furthermore, you must not share your username or
                  password with any other person and agree to keep those details
                  in a safe and secure location. You acknowledge that ESG
                  Reporting Intelligence is not responsible for third-party
                  access to your account that results from theft or
                  misappropriation of your username and passwords.
                </p>
                <p className="justify-content-end textPara">
                  <b>(b) Information Submitted.</b> You are solely responsible
                  for, and assume all liability for:
                </p>
                <p className="justify-content-end textPara">
                  <b>(i)</b> any, and all, information and content you enter or
                  submit through the Website or during the use of the Services;
                </p>
                <p className="justify-content-end textPara">
                  <b>(ii)</b> the information and content you make available in
                  any manner through the service; and
                </p>
                <p className="justify-content-end textPara">
                  <b>(iii)</b> your interaction with any, and all, third
                  parties.
                </p>
                <p className="justify-content-end textPara">
                  <b>(c)</b> Risk Assumption and Precautions. You assume all
                  risks when using the Services, including, but not limited to,
                  all of the risks associated with any interactions with third
                  parties, such as service providers with whom you are matched,
                  referred or provided.
                </p>
                <p className="justify-content-end textPara">
                  <b>(d) No Guarantees.</b> You agree and acknowledge that we
                  may not be able to provide you with a suitable match and we
                  make no guarantee with respect to the number of matches or
                  that our panel of service providers will be able to meet your
                  business needs.
                </p>
                <p className="justify-content-end textPara">
                  <b>(e) No False Information.</b> You agree and acknowledge
                  that all information and content that you submit or provider
                  to us through the website and application is accurate, true,
                  current and complete information.
                </p>
                <p className="justify-content-end textPara">
                  <b>(f) Legal Purpose.</b> You agree to not use the website,
                  application, or Services for any illegal purpose. You will
                  only use the Website, Application or Service in accordance
                  with federal, state and local laws.
                </p>
                <p className="justify-content-end textPara">
                  <b>(g) Business Purpose.</b> You agree to only use the
                  website, application, or Services for a bona fide business
                  purpose and not for personal purposes. In addition, your
                  agreement not to use the website, application, or Services to
                  obtain information about or make decisions about anyone but
                  yourself and/or your business. When registering on behalf of a
                  business you hereby declare that you have the authority to
                  bind that business to these terms and conditions.
                </p>
                <h4 className="justify-content-end textHead">
                  3. Proprietary Rights
                </h4>
                <p className="justify-content-end textPara">
                  <b>(a) Ownership of Proprietary Information. </b> You
                  acknowledge and agree that ESG Reporting Intelligence is the
                  owner of all rights in and to the website, application and
                  Services. Federal, state, and local laws and regulations
                  protect these proprietary rights. You are permitted to use the
                  website, application, and Services only as expressly
                  authorised by these terms and conditions. You must not copy,
                  reproduce, distribute or create derivative works, reverse
                  engineer or reverse compile any of the Website or Services or
                  technology.
                </p>
                <p className="justify-content-end textPara">
                  <b>(b) No Use of Confidential Information. </b> You must not
                  post, copy, modify, transmit, disclose, show in public, create
                  any derivative works from, distribute, make commercial use, or
                  reproduce in any way:
                </p>
                <p className="justify-content-end textPara">
                  <b>(i)</b> confidential information or
                </p>
                <p className="justify-content-end textPara">
                  <b>(ii)</b> any other copyrighted material, trademarks, or other
                  proprietary information accessible via the website,
                  application or Services, without first obtaining the prior
                  written consent of the owner of such proprietary rights.
                </p>
                <p className="justify-content-end textPara">
                  <b> (c) Licence to Provided Content. </b> By providing
                  information or content within your Registered User account or
                  any public area of the website, application or Service, you
                  agree to grant to us, and you represent and warrant that you
                  have the right to grant to us, an irrevocable, perpetual,
                  non-exclusive, fully-paid, worldwide license to use,
                  reproduce, publicly perform, publicly display and distribute
                  such information and content, and to prepare derivative works
                  of, or incorporate into other works, such information and
                  content, and to grant and authorise sub-licenses of the
                  foregoing.
                </p>
                <p className="justify-content-end textPara">
                  <b>(d) Enhancements.</b> From time to time, we may create,
                  test or implement new features or services on the website and
                  application in which you may voluntarily choose to
                  participate, in accordance with the additional terms and
                  conditions of such features or programs. By your voluntary
                  participation in such features or programs, you grant us the
                  rights stated in this subsection in connection with the
                  additional terms and conditions (if any) of such features or
                  services.
                </p>
                <h4 className="justify-content-end textHead">
                4. User Information
                </h4>
                <p className="justify-content-end textPara">
                  <b> (a) Privacy Policy.</b> For information about the
                  collection and possible use of information and material
                  provided by you, please refer to our Privacy Policy, a copy of
                  which can befound on our website and application. By using the
                  website, application or the Services, you consent to the use
                  of data and information in accordance with our Privacy Policy.
                </p>
                <p className="justify-content-end textPara">
                  <b> (b) Disclosure by Law. </b> You acknowledge and agree that
                  we may disclose information you provide if required to do so
                  by law, at the request of a third-party, or if we, in our sole
                  discretion, believe that disclosure is reasonable to do so:
                </p>
                <p className="justify-content-end textPara">
                  <b>(i)</b> comply with the law, requests or orders from law enforcement,
                  or any legal process (whether or not such disclosure is
                  required by applicable law); or
                </p>
                <p className="justify-content-end textPara">
                  <b>(ii)</b> protect or defend our, or a third-party’s, rights or property.
                  This includes all data obtained from third party information
                  systems (e.g. cloud accounting platforms) as authorised by you
                  during your use.
                </p>


                <p className="justify-content-end textPara">
                <b>(c)</b> Information Security. We work hard to protect ESG Reporting Intelligence and our
                users from unauthorised access to or unauthorised alteration, disclosure or destruction of
                information we hold. In particular:</p>
                <p className="justify-content-end textPara">
                <b>(i)</b> we encrypt many of our services using SSL.</p>
                <p className="justify-content-end textPara">
                <b>(ii)</b> we review our information collection, storage and processing practices, including
                physical security measures, to guard against unauthorised access to systems.</p>
                <p className="justify-content-end textPara">
                <b>(iii)</b> we restrict access to personal information at ESG Reporting Intelligence to
                employees, contractors and agents who need to know that information and we
                ensure that persons with access to personal information are subject to strict
                contractual confidentiality obligations and may be disciplined or terminated if they
                fail to meet these obligations.</p>
                <p className="justify-content-end textPara">
                <b>(d) Use of Anonymous Information for Research.</b> By using the website, application and/or
                Services, you agree to allow us to anonymously use your information and your
                experiences through the Services to continue our research into successful business
                practices and to improve the Services.</p>



                <h4 className="justify-content-end textHead">5. Services</h4>
                <p className="justify-content-end textPara">
                We help business owners and staff to populate and create reports. These proprietary reports are
                created by ESG Reporting Intelligence’s experience consulting to businesses and people on
                environmental, economic, social and governance programs. Our proprietary technology (“the
                Services”) helps facilitate the gathering of business information.
                As part of the Services, we may request that you provide various items of information to be used
                in order to provide 3 rd party partners for your business needs. By using our Services, you permit
                the transfer (electronically) of these items of information onto the specific providers that the
                system links you too.</p>
                <h4 className="justify-content-end textHead">6. Links to Third-Party websites / Dealings with Advertisers and Sponsors</h4>
                <p className="justify-content-end textPara">
                The website, application and Services may contain links to websites of third-parties, including
                without limitation, advertisers, which are not under the control of ESG Reporting Intelligence. We
                are not responsible for the content of any linked website or any link contained in a linked website,
                or any changes or updates to such websites. We provide these links to you as a convenience,
                and the inclusion of any link does not imply that we endorse or accept any responsibility for the
                content on those third-party websites.<br />
                Your correspondence or business dealings with, or participation in promotions of, advertisers or
                third parties found on or through the website, applications or Services are solely between you
                and such advertiser or third-party. You agree that we will not be responsible or liable for any loss
                or damage of any sort incurred as the result of the presence of such advertisers on the website,
                application or Services.</p>





                <h4 className="justify-content-end textHead">7. Disclaimer</h4>
                <p className="justify-content-end textPara"><b>(a) No Warranties.</b> To the extent permitted by law, we do not give any warranties with
                respect to the provision of Services. We provide the services on an ‘as is’ and ‘as
                available’ basis and provide no warranties of any kind, whether express, implied,
                statutory or otherwise with respect to the Services, application or the website, including
                any implied warranties or merchantability, fitness for a particular purpose or non-
                infringement. We do not warrant that your use of the services will be secure,
                uninterrupted, always available, error-free or will meet your requirements, or that any
                defects in the service will be corrected. We disclaim all liability for, and no warranty is
                made with respect to, the connectivity and availability of the services.</p>
                <p className="justify-content-end textPara"><b>(b) Third-Party Content.</b> Opinions, advice, statements, offers, or other information or
                content made available through the website, application or Services, but not directly by
                ESG Reporting Intelligence, are those of their respective authors, and should not
                necessarily be relied upon. Such authors are solely responsible for such content. We do
                not:</p>
                <p className="justify-content-end textPara"><b>(i)</b> guarantee the accuracy, completeness or usefulness of any information provided
                on the services, or</p>
                <p className="justify-content-end textPara"><b>(ii)</b> adopt, endorse or accept responsibility for the accuracy or reliability of any
                opinion, advice or statement made by any party other than ESG Reporting
                Intelligence. Under no circumstances will we be responsible for any loss or
                damage resulting from anyone’s reliance on information or other content posted
                on the website, application or transmitted to or by any users.</p>
                <p className="justify-content-end textPara"><b>(c) Beta Features.</b> From time to time, we may offer new “beta” features or tools with which
                its users may experiment on the website, application or Services. Such features or tools
                are offered solely for experimental purposes and without any warranty of any kind and
                may be modified or discontinued at our sole discretion. The provisions of this disclaimer
                section apply to such features or tools.</p>
                <h4 className="justify-content-end textHead">8. Limitation of Liability</h4>
                <p className="justify-content-end textPara"><b>(a) Incidental Damages and Aggregate Liability.</b> ESG Reporting Intelligence shall not be
                liable for any incidental, special, consequential or indirect damages arising out of or
                relating to the use or inability to use the services, including, without limitation, damages
                for loss or corruption of data or programs, service interruptions and procurement of
                substitute services, even if we know or has been advised of the possibility of such
                damages.</p>
                <p className="justify-content-end textPara"><b>(b) No Liability.</b> To the maximum extent permitted by law, ESG Reporting Intelligence shall
                not be liable for any damages whatsoever, whether direct, indirect, general, special,
                compensatory, consequential, and/or incidental, arising out of or relating to the conduct
                of you or anyone else in connection with the use of the services.</p>
                <p className="justify-content-end textPara"><b>(c) Information Verification.</b> We may use various methods of verifying information that
                users have provided. However, none of those ways are perfect, and you acknowledge
                and agree that we and our agents and contractors will have no liability to you arising from
                any incorrectly verified information.</p>
                <h4 className="justify-content-end textHead">9. Indemnification</h4> 
                <p className="justify-content-end textPara">You agree to indemnify, defend and hold harmless ESG Reporting Intelligence, its officers, directors,
                employees, agents and third parties, for any losses, costs, liabilities and expenses (including
                reasonable legal fees) relating to or arising out of:</p>
                <p className="justify-content-end textPara"><b>(i)</b> your use of or inability to use the website, application or Services,</p>




                <p className="justify-content-end textPara"><b>(ii)</b> any user postings made by you,</p>
                <p className="justify-content-end textPara"><b>(iii)</b>  your violation of any of these terms and conditions or your violation of any rights of a third- party, or</p>
                <p className="justify-content-end textPara"><b>(iv)</b> your violation of any applicable laws, rules or regulations.
                We reserve the right, at its own cost, to assume the exclusive defence and control of any matter
                otherwise subject to indemnification by you, in which event you will fully cooperate with us in asserting
                any available defences.</p>
                <h4 className="justify-content-end textHead">10. Communication and Privacy</h4>
                <p className="justify-content-end textPara">We may use your email address to send you messages notifying you of important changes to the
                Services or special offers. Further, we may contact you by telephone if you voluntarily provide us with
                your telephone number, in order to communicate with you regarding the Services. If you do not want
                to receive such email messages or telephone calls, please refer to our Privacy Policy.</p>
                <h4 className="justify-content-end textHead">11. Terms and Termination</h4>
                <p className="justify-content-end textPara">This agreement will become effective upon your acceptance of the terms and conditions by your use
                of the website, application or Services and will remain in effect in perpetuity unless terminated.
                Either you, upon written request, or us may terminate your use or access at any time, for any reason
                or no reason, without explanation. We reserve the right to immediately suspend or terminate your
                access to any of the Services, without notice, for any reason or no reason. We also reserve the right
                to remove your information or data from our Services and any other records at any time at our sole
                discretion.<br />
                In the event your access to any of the Services is suspended, you agree that all fees then paid to us
                by you will be non-refundable and all outstanding or pending payments will immediately be due.</p>
                <h4 className="justify-content-end textHead">12. General Provisions</h4>
                <p className="justify-content-end textPara"><b>(a) Controlling Law and Jurisdiction.</b> You agree that these terms and conditions, the website,
                application and the Services are governed by the laws of New South Wales and that any
                dispute arising out of or relating to these terms and conditions, the website, application or the
                Services will be subject to the exclusive jurisdiction and venue of the courts within that state.
                You acknowledge and agree that any violation of these terms and conditions may cause us
                irreparable harm, and therefore you agree that we will be entitled to seek extraordinary relief
                in court, including but not limited to temporary restraining orders, preliminary injunctions and
                permanent injunctions without the necessity of posting a bond or other security, in addition to
                and without prejudice to any other rights or remedies that we may have for a breach of these
                terms and conditions.</p>
                <p className="justify-content-end textPara"><b>(b) Miscellaneous.</b> These terms and conditions, which you accept upon registration for the
                Services or application, the Privacy Policy located on the website and application, and any
                applicable payment, renewal, additional services terms, comprise the entire agreement
                between you and us regarding the use of this Service, superseding any prior agreements
                between you and us related to your use of this website or Services (including, but not limited
                to, any prior versions of this Terms).</p>
                <p className="justify-content-end textPara"><b>(c) FAQs.</b> The FAQ’s found on the Services or application are for informational purposes only
                and are not deemed to be part of these terms and conditions.</p>
                <p className="justify-content-end textPara"><b>(d) Survival.</b> Unless otherwise explicitly stated, these terms and conditions will survive
                termination of your registration as a Registered User.</p>
                <p className="justify-content-end textPara"><b>(e) Non-waiver.</b> The failure of ESG Reporting Intelligence to exercise or enforce any right or
                provision of these Terms does not constitute a waiver of such right or provision.</p>

                <p className="justify-content-end textPara"><b>(f) Severability.</b> If any provision of these terms and conditions are held to be invalid, the
                remainder of the terms and conditions will continue in full force and effect.</p>
                <br />
                <b>Last Updated: 20 October 2021.</b>


              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
