/** @format */

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import SettingBilling from './component/Company Admin/component/setting/billing';
import Google2FA from './component/Company Admin/component/setting/google2FA';
import SettingProfile from './component/Company Admin/component/setting/my_profile';
import Checkout from './component/Company Admin/component/setting/payment_success';
import Restrictsubscription from './component/Company Admin/component/setting/restrictsubscription';
import SmartUp from './component/Company Admin/component/setting/SmartUp';
import SettingSubAdmin from './component/Company Admin/component/setting/sub_admin';
import EsgReportingPie from './component/Company Admin/esg_repoting/esg_reporting_pie';
import invoice from './component/Company Admin/Setting/invoice';
import setting from './component/Company Admin/Setting/setting';
import user_detail from './component/Company Admin/Setting/user_detail';
// import ScopeEmission1 from "./component/Company Sub Admin/Component/carbon_footprint/scope_emmision_1";
// import ScopeEmission2 from "./component/Company Sub Admin/Component/carbon_footprint/scope_emmision_2";
// import ScopeEmission3 from "./component/Company Sub Admin/Component/carbon_footprint/scope_emmision_3";
import CyberTechnology from './component/Company Sub Admin/Component/Governance/cyber_technology';
import EnviornmentalPolicy from './component/Company Sub Admin/Component/Governance/enviornmental_policy';
import GovernancePolicies from './component/Company Sub Admin/Component/Governance/governance';
import HealthSafety from './component/Company Sub Admin/Component/Governance/health_safety_policy';
import OtherPolicies from './component/Company Sub Admin/Component/Governance/other_policy';
import SocialPolicies from './component/Company Sub Admin/Component/Governance/social_policies';
import BusinessModel from './component/Company Sub Admin/Component/Sector Questions/Business_Model';
import Cyber_Digital from './component/Company Sub Admin/Component/Sector Questions/Cyber_digital';
import EnvironmentalCapital from './component/Company Sub Admin/Component/Sector Questions/Enviornmental_Capital';
import Human_Capital from './component/Company Sub Admin/Component/Sector Questions/Human_capital';
import LeadershipGovernance from './component/Company Sub Admin/Component/Sector Questions/Leadership_&_Governance';
import SocialCapital from './component/Company Sub Admin/Component/Sector Questions/Social_Capital';
import BusinessModelTopics from './component/Company Sub Admin/Component/Supplier/business_model';
import GovernanceTopics from './component/Company Sub Admin/Component/Supplier/governance_topics';
import EnviornmentalTopics from './component/Company Sub Admin/Component/Supplier/supplier_enviornmental_topics';
import SocialTopics from './component/Company Sub Admin/Component/Supplier/supplier_social_topics';
import NotFoundRoute from './component/Company Sub Admin/Pages/404';
import board_skills from './component/Company Sub Admin/Pages/board_skills/board_skills';
import board_skills_detail from './component/Company Sub Admin/Pages/board_skills/board_skills_detail';
import carbon_footprint from './component/Company Sub Admin/Pages/carbon_footprint/carbon_footerprint';
import carbon_footprint_detail from './component/Company Sub Admin/Pages/carbon_footprint/carbon_footprint_detail';
import carbon_footprint_tabbing from './component/Company Sub Admin/Pages/carbon_footprint/carbon_footprint_tabbing';
import result from './component/Company Sub Admin/Pages/carbon_footprint/result';
import esg_products from './component/Company Sub Admin/Pages/esg_products/esg_products';
import esg_reporting from './component/Company Sub Admin/Pages/esg_reporting/esg_reporting';
import governance from './component/Company Sub Admin/Pages/governance/governance';
import governance_fast from './component/Company Sub Admin/Pages/governance/governance_fast';
import Home from './component/Company Sub Admin/Pages/home/home';
import management from './component/Company Sub Admin/Pages/management/management';
import managementDetail from './component/Company Sub Admin/Pages/management/management_detail';
import news from './component/Company Sub Admin/Pages/news/news';
import completion from './component/Company Sub Admin/Pages/requested_reports/completion';
import requested_reports from './component/Company Sub Admin/Pages/requested_reports/requested_reports';
import sector_question from './component/Company Sub Admin/Pages/sector_question/sector_question';
import sector_question_detail from './component/Company Sub Admin/Pages/sector_question/sector_question_detail';
import sector_question_fast from './component/Company Sub Admin/Pages/sector_question/sector_question_fast';
import supplier_management from './component/Company Sub Admin/Pages/supplier_management/supplier_management';
import supplier_management_detail from './component/Company Sub Admin/Pages/supplier_management/supplier_management_detail';
import supplier_management_form from './component/Company Sub Admin/Pages/supplier_management/supplier_management_form';
import supplier_management_option from './component/Company Sub Admin/Pages/supplier_management/supplier_management_option';
import supplier from './component/Company Sub Admin/Pages/supplier/supplier';
import supplier_details from './component/Company Sub Admin/Pages/supplier/supplier_detail';
import supplier_fast from './component/Company Sub Admin/Pages/supplier/supplier_fast';
import supplier_form from './component/Company Sub Admin/Pages/supplier/supplier_form';
import download_report from './component/Company Sub Admin/Pages/sustainable/download_report';
import sustainable from './component/Company Sub Admin/Pages/sustainable/sustainable';
import sustainable_submissions from './component/Company Sub Admin/Pages/sustainable/sustainable-second';
import CoachingForm from './component/Company Sub Admin/Pages/training/coaching_form';
import continuing_professional_development_detail from './component/Company Sub Admin/Pages/training/continuing_professional_development';
import training from './component/Company Sub Admin/Pages/training/training';
import AdminHeader from './component/header/admin_header';
import Header from './component/header/header';
import AddSubAdmin from './component/Login/addSubAdmin';
import Login from './component/Login/login';
import NewPassword from './component/Login/NewPassword';
import OTPVerify from './component/Login/otpverify';
import ResetMassage from './component/Login/resetMassage';
import ResetPass from './component/Login/resetpass';
import AdminSidebar from './component/sidebar/admin_sidebar';
import Sidebar from './component/sidebar/sidebar';
import CompanyInvite from './component/signup/company_invite';
import Invite from './component/signup/invite';
import Privacy from './component/signup/privacy';
import Signup from './component/signup/signup';
import SuperSubAdminInvite from './component/signup/SuperSubAdminInvite';
import Terms from './component/signup/terms';
// import SupplierManagement from "./component/Company_Super_Admin/supplier-management/supplier_management";
import Introduction from './component/Supplier/introduction';
import supplierForm from './component/Supplier/supplierForm';
import SupplierQuestionDetail from './component/Supplier/SupplierQuestionDetail';
import EmailVerification from './component/Verfications/EmailVerfications';
import Massage from './component/Verfications/massage';
// import EnviornmentalPolicyTabbing from "./component/Company_Super_Admin/component/companies/enviornmental_policy";
import OtpVerification from './OtpVerification';
import ScrollToTop from './scrollToTop';
import ScopeEmissions from './component/Company Sub Admin/Component/carbon_footprint/ScopeEmissions.jsx';
import './App.css';
import ProtectedRoute from './guards/ProtectedRoute.js';
import { AuthProvider } from './guards/AuthContext.js';
import Forbidden from './component/Company Sub Admin/Pages/403.js';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <OtpVerification />
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Login}></Route>
            <ProtectedRoute
              exact
              path="/home"
              component={Home}
              allowedRoles={['regular', 'trial']}
            />

            <Route exact path="/Login" component={Login}></Route>
            <Route exact path="/otpverify/:token" component={OTPVerify}></Route>
            <Route exact path="/ResetPass" component={ResetPass}></Route>
            <Route exact path="/verify/:token" component={NewPassword}></Route>
            <Route
              exact
              path="/email/verify/:token"
              component={EmailVerification}
            ></Route>

            <Route exact path="/AddSubAdmin" component={AddSubAdmin}></Route>
            <Route exact path="/signup/:signup?" component={Signup}></Route>
            <Route exact path="/privacy_policy" component={Privacy}></Route>
            <Route exact path="/terms_and_conditions" component={Terms}></Route>
            <Route exact path="/sidebar" component={Sidebar}></Route>
            <Route exact path="admin/sidebar" component={AdminSidebar}></Route>
            <Route exact path="/header" component={Header}></Route>
            <Route exact path="admin/header" component={AdminHeader}></Route>
            <Route exact path="/invite/:uuid" component={Invite}></Route>
            <Route exact path="/verify_message" component={Massage}></Route>
            <Route exact path="/reset_massage" component={ResetMassage}></Route>
            <Route
              exact
              path="/SubAdmin/invite/:uuid"
              component={SuperSubAdminInvite}
            ></Route>
            <Route
              exact
              path="/company_invite/:uuid"
              component={CompanyInvite}
            ></Route>

            <Route
              exact
              path="/checkout/:success/:sessionId"
              component={Checkout}
            ></Route>

            <ProtectedRoute
              exact
              path="/sector_questions"
              component={sector_question}
              allowedRoles={['regular']}
            />
            <ProtectedRoute
              exact
              path="/Environmental_Capital"
              component={EnvironmentalCapital}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/Social_Capital"
              component={SocialCapital}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/Leadership_Governance"
              component={LeadershipGovernance}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/Business_Model_Innovation"
              component={BusinessModel}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/Cyber_Digital"
              component={Cyber_Digital}
              allowedRoles={['regular']}
            />
            <ProtectedRoute
              exact
              path="/Human_Capital"
              component={Human_Capital}
              allowedRoles={['regular']}
            />
            <ProtectedRoute
              exact
              path="/sector_question_fast"
              component={sector_question_fast}
              allowedRoles={['regular']}
            />
            <ProtectedRoute
              exact
              path="/sector_question_detail"
              component={sector_question_detail}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/sustainable"
              component={sustainable}
              allowedRoles={['regular']}
            />
            <ProtectedRoute
              exact
              path="/sustainable_submissions"
              component={sustainable_submissions}
              allowedRoles={['regular']}
            />
            {/* <Route exact path="/esg_reporting" component={esg_reporting}></Route> */}

            <ProtectedRoute
              exact
              path="/governance"
              component={governance}
              allowedRoles={['regular']}
            />
            <ProtectedRoute
              exact
              path="/governance_fast"
              component={governance_fast}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/suppliers"
              component={supplier}
              allowedRoles={['trial', 'regular']}
            />

            <ProtectedRoute
              exact
              path="/suppliers_fast"
              component={supplier_fast}
              allowedRoles={['trial', 'regular']}
            />

            <ProtectedRoute
              exact
              path="/supplier_management"
              component={supplier_management}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/requested_reports"
              component={requested_reports}
              allowedRoles={['regular']}
            />
            <ProtectedRoute
              exact
              path="/carbon_footprint"
              component={carbon_footprint}
              allowedRoles={['regular']}
            />
            <ProtectedRoute
              exact
              path="/carbon_footprint_tabbing"
              component={carbon_footprint_tabbing}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/result"
              component={result}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/carbon_footprint_detail"
              component={carbon_footprint_detail}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/board_skills"
              component={board_skills}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/board_skills_detail"
              component={board_skills_detail}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/management"
              component={management}
              allowedRoles={['regular', 'trial']}
            />

            <ProtectedRoute
              exact
              path="/management_details"
              component={managementDetail}
              allowedRoles={['regular', 'trial']}
            />

            <ProtectedRoute
              exact
              path="/esg_products"
              component={esg_products}
              allowedRoles={['regular', 'trial']}
            />
            <ProtectedRoute
              exact
              path="/training"
              component={training}
              allowedRoles={['regular', 'trial']}
            />

            <ProtectedRoute
              exact
              path="/continuing_professional_development_detail"
              component={continuing_professional_development_detail}
              allowedRoles={['regular', 'trial']}
            />

            <ProtectedRoute
              exact
              path="/news"
              component={news}
              allowedRoles={['regular', 'trial']}
            />

            <ProtectedRoute
              exact
              path="/supplier_details"
              component={supplier_details}
              allowedRoles={['regular', 'trial']}
            />

            <ProtectedRoute
              exact
              path="/supplier_form"
              component={supplier_form}
              allowedRoles={['regular', 'trial']}
            />

            <ProtectedRoute
              exact
              path="/supplier_management_option"
              component={supplier_management_option}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/supplier_management_form"
              component={supplier_management_form}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/supplier_management_detail"
              component={supplier_management_detail}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/download_report"
              component={download_report}
              allowedRoles={['regular']}
            />
            <ProtectedRoute
              exact
              path="/coaching_form"
              component={CoachingForm}
              allowedRoles={['regular', 'trial']}
            />

            <ProtectedRoute
              exact
              path="/Governance_Policies"
              component={GovernancePolicies}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/Social_Policies"
              component={SocialPolicies}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/Cyber_&_Technology"
              component={CyberTechnology}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/Health_&_Safety_Policy"
              component={HealthSafety}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/Environmental_Policy"
              component={EnviornmentalPolicy}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/other_policies"
              component={OtherPolicies}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/Environmental_Topics"
              component={EnviornmentalTopics}
              allowedRoles={['regular', 'trial']}
            />

            <ProtectedRoute
              exact
              path="/Social_Topics"
              component={SocialTopics}
              allowedRoles={['regular', 'trial']}
            />

            <Route exact path="/privacy_policy" component={Privacy}></Route>

            <ProtectedRoute
              exact
              path="/Governance_Topics"
              component={GovernanceTopics}
              allowedRoles={['regular', 'trial']}
            />

            <ProtectedRoute
              exact
              path="/Business_Models"
              component={BusinessModelTopics}
              allowedRoles={['regular', 'trial']}
            />

            <ProtectedRoute
              exact
              path="/carbon_emissions"
              component={ScopeEmissions}
              allowedRoles={['regular']}
            />

            {/* <Route
              exact
              path="/Scope_1_Emissions"
              component={ScopeEmission1}
            ></Route>

            <Route
              exact
              path="/Scope_2_Emissions"
              component={ScopeEmission2}
            ></Route>

            <Route
              exact
              path="/Scope_3_Emissions"
              component={ScopeEmission3}
            ></Route> */}

            <Route
              exact
              path="/Profile_Settings"
              component={SettingProfile}
            ></Route>

            <Route
              exact
              path="/Setting_Billing"
              component={SettingBilling}
            ></Route>
            <Route
              exact
              path="/subscription_plan/:uuid"
              component={Restrictsubscription}
            ></Route>

            <Route
              exact
              path="/sub_accounts"
              component={SettingSubAdmin}
            ></Route>

            <Route exact path="/Google_2FA" component={Google2FA}></Route>
            <Route exact path="/smartup" component={SmartUp}></Route>

            {/* Company Admin */}
            <Route exact path="/setting" component={setting}></Route>

            <ProtectedRoute
              path="/esg_reporting"
              component={esg_reporting}
              allowedRoles={['regular']}
            />

            <ProtectedRoute
              exact
              path="/esg_reporting_pie"
              component={EsgReportingPie}
              allowedRoles={['regular']}
            />
            {/* need images */}

            <Route
              exact
              path="/details/:uuid1/invoice"
              component={invoice}
            ></Route>
            <Route
              exact
              path="/sub_accounts/:uuid"
              component={user_detail}
            ></Route>

            {/* Company Super Admin */}
            <Route
              exact
              path="/supplier/Introduction"
              component={Introduction}
            ></Route>
            <Route
              exact
              path="/supplier/supplierForm"
              component={supplierForm}
            ></Route>
            <Route
              exact
              path="/supplier/SupplierQuestionDetail"
              component={SupplierQuestionDetail}
            ></Route>
            <Route exact path="/completion" component={completion}></Route>
            <Route path="/403" component={Forbidden} />
            <Route path="*" component={NotFoundRoute} />
          </Switch>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
