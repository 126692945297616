import React, { Component } from "react";
import axios from "axios";
//import ReCAPTCHA from "react-google-recaptcha";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";

import { authenticationService } from "../../_services/authentication";
import config from "../../config/config.json";
//import env from "../../env";
import logo from "../../img/logoNew.png";

import { setStore, isValidEmail } from "../../utils/UniversalFunction";
import "../sidebar/common.css";
import "./login.css";
import { withRouter } from 'react-router-dom';

const baseURL = config.baseURL;
class signup extends Component {
  constructor(props) {
    super(props);
    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      this.props.history.push("/home");
    }
    this.state = {
      user: {
        email: "",
        password: "",
        firstname: "",
        lastname: "",
        isLoggedIn: false,
        userInfo: {
          name: "",
          emailId: "",
        },
      },
      captchaIsVerified: true,
      type: "password",
      submitted: false,
      captchaVerification: true,
      emailvalidation: false,
      emailvalidationMessage: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitV2 = this.handleSubmitV2.bind(this);
    this.handleVerification = this.handleVerification.bind(this);
    this.showHide = this.showHide.bind(this);
  }


  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "password" ? "input" : "password",
    });
  }

  handleVerification() {
    this.setState({
      captchaIsVerified: true,
    });
  }

  // Logout Session and Update State
  logout = (response) => {
    let userInfo = {
      name: "",
      emailId: "",
    };
    this.setState({ userInfo, isLoggedIn: false });
  };

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    if (name === "email") {
      let condition = isValidEmail(value);
      if (condition === true) {
        this.setState({
          emailValidation: true,
          emailValidationMessage: "",
        });
      } else {
        this.setState({
          emailValidation: false,
          emailValidationMessage: "Please check email format",
        });
      }
    }
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { user, captchaIsVerified, emailValidation } = this.state;
    let pushToRoute = "";

    if (user.email && user.password && captchaIsVerified) {
      if (emailValidation) {
        let referenceData = window.localStorage.getItem("reference");

        axios
          .post(config.OLD_API_URL + "login", {
            email: user.email,
            password: user.password,
            token: captchaIsVerified,
            reference: referenceData,
          })
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: response.data.message,
              showConfirmButton: true,
              timer: 1000,
            });

            let setResponse = {};
            setResponse.data = response.data;
            setResponse.data.role = response.data.role;
            let setSecondResponse = response.data.twoFaStatus;
            let setSubsStatusCode = response.data.subscriptionStatus;
            localStorage.setItem("currentUser", JSON.stringify(setResponse));
            localStorage.setItem("user_status", response.data.userStatus);
            localStorage.setItem(
              "2faStatus",
              JSON.stringify(setSecondResponse)
            );
            localStorage.setItem(
              "subscriptionAuth",
              JSON.stringify(setSubsStatusCode)
            );
            let check2faStatus = response.data.twoFaStatus === 0;
            let subscriptionStatus = response.data.subscriptionStatus;
            if (check2faStatus) {
              pushToRoute = "/OTPVerify";
            } else {
              if (subscriptionStatus === "400") {
                pushToRoute = "/subscription_plan";
              } else if (subscriptionStatus === "200") {
                pushToRoute = "/home";
              }
            }
            let finalLink = "";
            if (response.data.link) {
              finalLink = response.data.link;
              setStore("reference", "");
            } else {
              finalLink = baseURL + pushToRoute;
            }
            setTimeout(() => {
              window.location.href = finalLink;
            }, 1000);
          })
          .catch(function (error) {
            if (error.response) {
              Swal.fire({
                icon: "error",
                title: error.response.data.message,
                showConfirmButton: true,
                timer: 1000,
              });
            }
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid Email",
          showConfirmButton: true,
          timer: 1000,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Please fill all input",
        showConfirmButton: true,
        timer: 1000,
      });
    }
  }

  handleSubmitV2(event) {
    event.preventDefault();

    
    this.setState({ submitted: true });
    const { user, captchaIsVerified, emailValidation } = this.state;
    if (user.email && user.password && captchaIsVerified) {
      if (emailValidation) {
        let referenceData = window.localStorage.getItem("reference");

        axios
          .post(config.OLD_API_URL + "loginV2", {
            email: user.email,
            password: user.password,
            token: captchaIsVerified,
            reference: referenceData,
          })
          .then((response) => {
            console.log("response", response);
             
            Swal.fire({
              icon: "success",
              title: "Loggedin Successfully",
              showConfirmButton: true,
              timer: 1000,
            });

            let setResponse = {};
            setResponse.data = response.data;
            setResponse.data.role = response.data.role;
            localStorage.setItem("currentUser", JSON.stringify(setResponse));
            localStorage.setItem("user_status", response.data.userStatus);

            setTimeout(() => {
              window.location.href = "/home"
            }, 1000);
            // this.props.history.push(`/home`);
          })
          .catch((error) => {
            if (error.response) {

              // if no plan found then redirect to billings page
              if(error?.response?.data?.type === "NO_PLAN_FOUND") {
                this.props.history.push(`/subscription_plan/${error?.response?.data?.uuid}`);
                return
              }

              // if no plan found then redirect to billings page
              if(error?.response?.data?.type === "2FA_VERIFY") {
                this.props.history.push(`/otpverify/${error?.response?.data?.temp_token}`);
                return
              }
             
              Swal.fire({
                icon: "error",
                title: error.response.data.message,
                showConfirmButton: true,
                timer: 1000,
              });
            }
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid Email",
          showConfirmButton: true,
          timer: 1000,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Please fill all input",
        showConfirmButton: true,
        timer: 1000,
      });
    }
  }

  componentDidMount() {
    if (authenticationService.currentUserValue) {
      this.props.history.push("/home");
    }
  }
  render() {
    const { user, submitted, type } = this.state;
    return (
      <div>
        <section className="login">
          <div className="login_part">
            <div className="sing_log">
              <div className="sing_one">
                l<img src={logo} alt="logo"  style={{width:"120px"}}/>
              </div>
              <div className="text_sing mb-4">
                <h4 className="Account">Login</h4>
                <p className="faster_oval">
                  Please fill out your information below to log into your
                  dashboard
                </p>
              </div>

              <form name="form" onSubmit={this.handleSubmitV2}>
                <div className="ster_form">
                  <div className="make_form">
                    <div className="row">
                      <div className="col-lg-12 col-xs-12">
                        <div className="login_bt form_sign">
                          <div
                            className={
                              "form-group fg" +
                              (submitted && !user.register_company_name
                                ? " has-error"
                                : "")
                            }
                          >
                            <label className="st_name" htmlFor="name">
                              Email
                            </label>
                            <input
                              className="form-control name_nf"
                              type="text"
                              name="email"
                              placeholder="Enter Register Email Address"
                              value={user.email}
                              onChange={this.handleChange}
                            />
                            {this.state.emailValidation === false && (
                              <div className="help-block">
                                {this.state.emailValidationMessage}
                              </div>
                            )}
                            {submitted && !user.email && (
                              <div className="help-block">
                                Email is required
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-xs-12">
                        <div className="form_sign password-eye">
                          <div className="img-eye">
                            <span onClick={this.showHide}>
                              {this.state.type === "input" ? (
                                <i className="fas fa-eye-slash"></i>
                              ) : (
                                <i className="fas fa-eye"></i>
                              )}
                            </span>
                          </div>
                          <div
                            className={
                              "form-group fg" +
                              (submitted && !user.email ? " has-error" : "")
                            }
                          >
                            <label className="st_name" htmlFor="name">
                              Password
                            </label>
                            <input
                              className="form-control name_nf"
                              type={type}
                              name="password"
                              placeholder="Enter your password"
                              value={user.password}
                              onChange={this.handleChange}
                            />

                            {submitted && !user.password && (
                              <div className="help-block">
                                Password is required
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-check reset">
                    <span>
                      <NavLink to="/ResetPass">Forgot Password?</NavLink>
                    </span>
                  </div>
                  <div className="make_form">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="bacei">
                          {/* <ReCAPTCHA
                            sitekey={env.GOOGLE_RECAPTCHA_SITE_KEY}
                            onChange={(e) => this.handleVerification(e)}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ gap: "20px" }}
                  >
                    <div className="global_link w-100">
                      {user.email && user.password ? (
                        <button
                          type="submit"
                          value="Submit"
                          className="page_width page_save w-100"
                        >
                          Login
                        </button>
                      ) : (
                        <button
                          type="submit"
                          value="Submit"
                          className="page_width page_save w-100 disabledd"
                        >
                          Login
                        </button>
                      )}
                    </div>
                    <div className="global_link w-100">
                      <NavLink
                        className="page_width page_save button_color w-100"
                        to="/signup"
                      >
                        REGISTER
                      </NavLink>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    );
  }
}


export default withRouter(signup);
