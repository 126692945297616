/**
 * eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import Frame from '../../img/Frame.png';
import React, { Component } from 'react';
import { authenticationService } from '../../_services/authentication';
import { history } from '../../_helpers/history';
import { Link, NavLink } from 'react-router-dom';
import config from '../../config/config.json';
import './header.css';
import Modal from 'react-bootstrap/Modal';
// import { Dropdown } from 'semantic-ui-react'
import China from '../../img/china.png';
import UK from '../../img/united-kingdom.png';
import Denmark from '../../img/denmark.png';
import Thai from '../../img/thainImg.jpg';
import Malay from '../../img/malayImg.png';

import {
  getSubscriptionAuth,
  checkIsAuthenticated,
} from '../../utils/UniversalFunction';
import { readCokkie } from '../../utils/UniversalFunction';

const ProfilePics =
  'https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png';

// const carbonURL = `http://127.0.0.1:3001/`;

const carbonURL = `https://staging-carbon.esgri.tech/#/`;
const currentUser = authenticationService.currentUserValue;

export default class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      usersData: [],
      name: '',
      profilePic: null,
      currentRole: '',
      show: false,
      selectedLang: '',
    };
    this.goToPreviousPath = this.goToPreviousPath.bind(this);
    this.routeChecking = this.routeChecking.bind(this);
    this.getProfileData = this.getProfileData.bind(this);
    this.calculateTime = this.calculateTime.bind(this);
  }
  goToPreviousPath() {
    window.history.back();
  }

  logout() {
    authenticationService.logout();
    history.push('/');
    localStorage.clear();
  }
  // getProfileData

  async routeChecking() {
    let data = await checkIsAuthenticated();
    let checkCondition = data.status === false;
    if (checkCondition) {
      localStorage.clear();
      return false;
    } else {
      const subscriptionAuth = getSubscriptionAuth('subscriptionAuth');
      if (subscriptionAuth === false || subscriptionAuth === undefined) {
        // window.location.href = "/subscription_plan";
      }
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser === null) {
        window.location.href = '/';
      } else {
        if (
          currentUser.data.role === 'super_admin' ||
          currentUser.data.role === 'sub_admin'
        ) {
          window.location.href = '/';
        }
      }
      return true;
    }
  }

  async getProfileData() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    await this.setState({ currentRole: currentUser.data?.role });
    const headers = {
      Authorization: `Bearer ${currentUser.data?.token}`,
      Accept: 'application/json',
    };

    fetch(config.API_URL + 'getProfileData', { headers })
      .then((res) => res.json())
      .then(
        (result) => {
          localStorage.setItem(
            'user_temp_id',
            JSON.stringify(result.user[0]?.id)
          );
          localStorage.setItem('role', JSON.stringify(result.user[0]?.role));
          localStorage.setItem('user_temp_email', result.user[0]?.email);
          localStorage.setItem('role', result.user[0]?.role);
          if (result.user[0]?.userCategory !== 'Partner') {
            this.calculateTime(result.user[0]?.subscriptionExpiredAt);
          }
          this.setState({
            isLoaded: true,
            usersData: result.user,
            name: result.user[0]?.firstName + ' ' + result.user[0]?.lastName,
            profilePic:
              result.user[0]?.logo === null
                ? ProfilePics
                : config.BASE_URL + result.user[0]?.logo,
          });
        },
        (error) => {
          window.location.href = '/';
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  calculateTime(endDate) {
    let url = document.URL.split('/');
    if (url[3] !== 'checkout') {
      if (endDate !== null) {
        let start = Date.now() / 1000;
        let end = endDate;
        let dd = start.toFixed(0);
        function getMinDiff(startDate, endDate) {
          let response = false;
          if (endDate < startDate) {
            response = true;
          }
          return response;
        }
        let diffMinutes = getMinDiff(dd, end);
        if (diffMinutes === true) {
          window.location.href = '/subscription_plan';
        }
      }
    }
  }

  // async handelLangSelectChange(event, data){

  //   let selectVal = data.value;

  //   let langCode = selectVal === "cn" ? "zh-CN" : selectVal === "db" ? "en" : "en";
  //   // update local storage
  //   localStorage.setItem("selectedLang", langCode);

  // }

  async componentDidMount() {
    const currentUser = await JSON.parse(localStorage.getItem('currentUser'));
    await this.setState({ currentRole: currentUser.data?.role });

    let getData = await this.routeChecking();
    let checkCondition = getData === false;
    if (checkCondition) {
      window.location.href = '/';
    } else {
      this.getProfileData();
    }
  }

  sidebarOpen() {
    const div = document.getElementById('sidebar-wrapper');
    const res = div.classList.contains('active-sidebar'); // true

    if (res) {
      document
        .getElementById('sidebar-wrapper')
        .classList.remove('active-sidebar');
      document.getElementById('sidebar-wrapper').style.display = 'block';
      document.getElementById('sidebar-wrapper').style.zIndex = '3';
      setTimeout(() => {
        document.getElementById('sidebar-wrapper').style.marginLeft = '0rem';
      }, 200);
    } else {
      document
        .getElementById('sidebar-wrapper')
        .classList.add('active-sidebar');
      document.getElementById('sidebar-wrapper').style.marginLeft = '-15rem';
      setTimeout(() => {
        document.getElementById('sidebar-wrapper').style.display = 'none';
      }, 200);
    }
  }

  handelLanguageChange = () => {
    var selectElement = document.querySelector('.goog-te-combo');

    if (
      selectElement.value === 'zh-CN' ||
      selectElement.value === 'en' ||
      selectElement.value === 'da'
    ) {
      this.setState({ selectedLang: selectElement.value });
      localStorage.setItem('selectedLang', selectElement.value);
    }
  };

  handleClose = () => {
    this.setState({ show: false });
    window.location.reload();

    // read cookie
  };
  //read

  handleShow = () => {
    (function () {
      function loadScript() {
        var googleTranslateScript = document.createElement('script');
        googleTranslateScript.type = 'text/javascript';
        googleTranslateScript.async = true;
        googleTranslateScript.src =
          'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';

        googleTranslateScript.error = () => {
          loadScript();
        };
        (
          document.getElementsByTagName('head')[0] ||
          document.getElementsByTagName('body')[0]
        ).appendChild(googleTranslateScript);
      }
      loadScript();
    })();

    this.setState({ show: true });
  };

  render() {
    const { name, profilePic, currentRole, show } = this.state;
    let fullUrl = window.location.href.split('/');

    let urr = '';
    if (fullUrl[3] !== 'sub_accounts') {
      urr = fullUrl.pop().toUpperCase();
    } else {
      urr = fullUrl[3];
    }
    let finalResult = urr.replaceAll('_', ' ');
    if (finalResult === 'SUPPLIER FAST') {
      finalResult = 'SUPPLIER';
    }
    if (finalResult === 'SUSTAINABLE') {
      finalResult = 'SUSTAINABLE DEVELOPMENT GOALS';
    }

    if (fullUrl[3] === 'checkout') {
      finalResult = 'Success';
    }

    let uri = window.location.pathname.split('/');
    let path = uri[1];

    if (path === 'Leadership_Governance') {
      finalResult = 'Leadership & Governance';
    }
    if (path === 'Cyber_Digital') {
      finalResult = 'Cyber & Digital';
    }
    if (path === 'Business_Model_Innovation') {
      finalResult = 'Business Model & Innovation';
    }

    if (path === 'suppliers_fast') {
      finalResult = 'Suppliers';
    }

    // const countryOptions = [
    //   { key: 'gb', value: 'gb', flag: 'gb', text: 'English' },
    //   { key: 'cn', value: 'cn', flag: 'cn', text: 'Chinese(simplified)' }
    // ]

    return (
      <div>
        <div className="d-flex" id="wrapper">
          {/* <!-- Page content wrapper--> */}
          <div id="page-content-wrapper">
            {/* <!-- Top navigation--> */}
            <nav className="navbar navbar-expand-lg  border-bottom navclassName background topbar_esg">
              <div className="container-fluid">
                {/* <!--  <button className="desh" id="sidebarToggle">Deshboard</button> --> */}
                <div className="deahbord " id="sidebarToggle">
                  <NavLink
                    to="#"
                    className="sidebar_bar"
                    onClick={this.sidebarOpen}
                  >
                    <i className="fas fa-bars"></i>
                  </NavLink>
                  <h4 className="back_quninti back_quninti_2">
                    <a className="back_text">
                      <span className="step_icon">
                        <i
                          onClick={this.goToPreviousPath}
                          className="far fa-long-arrow-left"
                        ></i>
                      </span>
                      {finalResult}
                    </a>
                  </h4>
                </div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon">
                    <i className="fas fa-bars"></i>
                  </span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                    <a href={`${carbonURL}login/${currentUser?.data?.token}`}>
                      <div className="headerContainer">
                        <img
                          src={Frame}
                          alt="frame"
                          className="frameDropdown"
                        />
                      </div>
                    </a>
                    <li className="nav-item">
                      <div>
                        {/* 
                      <Dropdown
                        placeholder='Select Country'
                        fluid
                        selection
                        options={countryOptions}
                        className="notranslate langSelector"
                        defaultValue={localStorage.getItem('selectedLang') === 'en' ? 'gb' : localStorage.getItem('selectedLang') === 'zh-CN' ? 'cn' : 'gb'}
                        onChange={this.handelLangSelectChange}
                      /> */}
                        {readCokkie() === '/en/en' ? (
                          <div
                            className="form-group has-search one languageCont"
                            onClick={this.handleShow}
                          >
                            <img
                              className="languageIconFlag"
                              src={UK}
                              alt="uk-flag"
                            />
                            <p className="notranslate">(EN)</p>
                          </div>
                        ) : readCokkie() === '/en/da' ? (
                          <div
                            className="form-group has-search one languageCont"
                            onClick={this.handleShow}
                          >
                            <img
                              className="languageIconFlag"
                              src={Denmark}
                              alt="denmark-flag"
                            />
                            <p className="notranslate">(DA)</p>
                          </div>
                        ) : readCokkie() === '/en/th' ? (
                          <div
                            className="form-group has-search one languageCont"
                            onClick={this.handleShow}
                          >
                            <img
                              className="languageIconFlag"
                              src={Thai}
                              alt="thai-flag"
                            />
                            <p className="notranslate">(TH)</p>
                          </div>
                        ) : readCokkie() === '/en/ms' ? (
                          <div
                            className="form-group has-search one languageCont"
                            onClick={this.handleShow}
                          >
                            <img
                              className="languageIconFlag"
                              src={Malay}
                              alt="malay-flag"
                            />
                            <p className="notranslate">(MS)</p>
                          </div>
                        ) : (
                          <div
                            className="form-group has-search one languageCont"
                            onClick={this.handleShow}
                          >
                            <img
                              className="languageIconFlag"
                              src={China}
                              alt="china-flag"
                            />
                            <p className="notranslate">(ZH-CH)</p>
                          </div>
                        )}
                        {/* {
                          localStorage.getItem('selectedLang') === "zh-CN" ? (
                            <img className="languageIconFlag" src={China} alt="china-flag" />
                          ): localStorage.getItem('selectedLang') === "da" ? (
                            <img className="languageIconFlag" src={Denmark} alt="denmark-flag"/>
                          ) : (
                            
                          )
                        } */}

                        {/* <p className="notranslate">{selectedLang === "en" ? "(EN)" : selectedLang === "zh-CN" ? "(ZH-CH)" : localStorage.getItem('selectedLang') ? `(${localStorage.getItem('selectedLang').toUpperCase()})` : "EN"}</p>               */}
                      </div>
                    </li>
                    <li className="nav-item dropdown text_down">
                      <div className="image_round">
                        <img
                          src={profilePic}
                          alt="Ban"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png';
                          }}
                          className="image--coverq"
                        />
                      </div>
                      <a
                        className="nav-link home_drop"
                        id="navbarDropdown"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {name}
                        <i
                          className="fa fa-caret-down elly"
                          aria-hidden="true"
                        ></i>
                        <p className="text_p">Business Owner</p>
                      </a>

                      <div
                        className="dropdown-menu dropdown-menu-end dropdown_menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link to="/Profile_Settings" className="dropdown-item">
                          <i className="fa fa-user"></i>
                          <span>My Profile</span>
                        </Link>
                        <Link to="/Google_2FA" className="dropdown-item">
                          <i className="fa fa-lock"></i>
                          <span>Two Factor Auth</span>
                        </Link>
                        {currentRole === 'company_admin' && (
                          <Link to="/Setting_Billing" className="dropdown-item">
                            <i className="fa fa-dollar-sign"></i>
                            <span>Subscription</span>
                          </Link>
                        )}

                        {currentRole === 'company_admin' && (
                          <Link to="/sub_accounts" className="dropdown-item">
                            <i className="fa fa-users"></i>
                            <span>Sub Admins</span>
                          </Link>
                        )}
                        {/* <Link to="/sub_accounts" className="dropdown-item">
                          <i className="fa fa-users"></i>
                          <span>Sub Admins</span>
                        </Link> */}
                        <Link
                          onClick={this.logout}
                          to="/"
                          className="dropdown-item"
                        >
                          <i className="fa fa-sign-out pull-right"></i>
                          <span>Logout</span>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            <Modal show={show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <div className="heading">
                  <h4>Change Language</h4>
                </div>
              </Modal.Header>
              <Modal.Body>
                <div
                  onClick={this.handelLanguageChange}
                  id="google_translate_element"
                ></div>
                <span className="googleBranding"></span>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
            {/* <!-- Page content--> */}
          </div>
          {/* <!-- main --> */}
        </div>
      </div>
    );
  }
}

<script></script>;
