/** @format */

import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import { Button, Modal } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';
import { Pagination, Icon } from 'semantic-ui-react';
import Swal from 'sweetalert2';

import { countryCodes } from '../../../../_constants/countryCodes';
import { authenticationService } from '../../../../_services/authentication';
import config from '../../../../config/config.json';
import logo from '../../../../img/EvoESG.png';
// import logo from "../../../../img/CoriolisESG.webp";
import info from '../../../../img/info-circle.svg';
import Header from '../../../header/header';
import Loader from '../../../loader/Loader';
import Sidebar from '../../../sidebar/sidebar';
import { readCokkie } from '../../../../utils/UniversalFunction';
import './supplier_management.css';
import 'react-datepicker/dist/react-datepicker.css';

const BASE_URL = config.BASE_URL;
const currentUser = authenticationService.currentUserValue;

export default class supplier_management_detail extends Component {
  csvLink = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      feeedbackLogs: [],
      submitted: false,
      limit: 10,
      skip: 0,
      startDate: '',
      endDate: '',
      totalCount: 0,
      setStartDate: null,
      setEndDate: null,
      list: [],
      perPage: 5,
      page: 0,
      pages: 0,
      login: false,
      activeModal1: '',
      activeModal: '',
      showModal1: '',
      comments: '',
      csvData: [],
      isEditableOrNot: false,
      isCoriolisRequested: false,
      requestReportModal: false,
      companiesDataModal: false,
      businessName: '',
      countryCode: '',
      countryCodes: countryCodes,
      foundCompanies: [],
      selectedCompany: null,
      isLoading: false,
      companiesPerPage: 9,
      currentPage: 0,
      showTooltip: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal1 = this.handleOpenModal1.bind(this);
    this.handleCloseModal1 = this.handleCloseModal1.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
    this.handleOpenRequestReportModal =
      this.handleOpenRequestReportModal.bind(this);
    this.handleCloseRequestReportModal =
      this.handleCloseRequestReportModal.bind(this);
    this.handleRequestReport = this.handleRequestReport.bind(this);
    this.handleRequestReportStepTwo =
      this.handleRequestReportStepTwo.bind(this);
    this.handleCloseCompaniesDataModal =
      this.handleCloseCompaniesDataModal.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handlePrevPage = this.handlePrevPage.bind(this);
    this.selectCountryCode = this.selectCountryCode.bind(this);
  }

  handleNextPage = () => {
    const { currentPage, companiesPerPage, foundCompanies } = this.state;

    const pageCount = Math.floor(foundCompanies.length / companiesPerPage);
    if (currentPage < pageCount) {
      this.setState({ currentPage: currentPage + 1 });
    }
  };

  handlePrevPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 0) {
      this.setState({ currentPage: currentPage - 1 });
    }
  };

  handleOpenModal1(val) {
    this.setState({ showModal1: true, activeModal1: 'login' });
    let userid = val.target.getAttribute('user-id');
    let id = val.target.getAttribute('data-id');
    this.setState({ idd: id, userid: userid });

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: 'application/json',
    };
    fetch(config.ADMIN_API_URL + `getSupplierFeedback/${id}/${userid}`, {
      headers,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            feeedbackLogs: result.result,
          });
        },

        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );
  }

  handleCloseModal1() {
    this.setState({ showModal1: false });
    this.setState({ showModal1: '' });
    window.location.reload();
  }

  async downloadReport(event) {
    event.preventDefault();
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
    };

    const lastReportFilename = await axios.get(
      config.BASE_URL + 'api/v1/supplierManagement/getTheLastReport',
      {
        headers,
      }
    );
    if (!lastReportFilename.data.filename) {
      Swal.fire({
        icon: 'info',
        title:
          'No reports was found, If you requested a report, please wait a few minutes and try again',
        showConfirmButton: false,
        timer: 2500,
      });
    }

    if (lastReportFilename.data.status === 'downloaded') {
      Swal.fire({
        icon: 'info',
        title:
          'Report found has already been downloaded, If you requested a report, please wait a few minutes and try again',
        showConfirmButton: false,
        timer: 2500,
      });
    }

    if (
      lastReportFilename.data.filename &&
      lastReportFilename.data.status === 'new'
    ) {
      const file = await axios.get(
        config.BASE_URL + 'api/v1/supplierManagement/downloadReport',
        {
          params: {
            filename: lastReportFilename.data.filename,
          },
          headers,
          responseType: 'blob',
        }
      );
      const url = window.URL.createObjectURL(file.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'coriolis-report';
      a.click();
      window.URL.revokeObjectURL(url);

      Swal.fire({
        icon: 'success',
        title: 'Check your downloads folder',
        showConfirmButton: false,
        timer: 2500,
      });
    }
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  async handleCloseModal() {
    this.setState({ showModal: false, showModal1: false });
  }

  handleOpenRequestReportModal() {
    this.setState({ requestReportModal: true });
  }

  async handleCloseRequestReportModal() {
    await this.setState({ requestReportModal: false });
  }

  async handleCloseCompaniesDataModal() {
    await this.setState({ companiesDataModal: false });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleRadioChange(event, selectedCompany) {
    if (event.target.checked) {
      this.setState({ selectedCompany: selectedCompany });
    }
  }

  selectCountryCode(e) {
    this.setState({ countryCode: e.target.value });
  }

  exportCSV(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        startDate: this.state.setStartDate,
        endDate: this.state.setEndDate,
      }),
    };

    fetch(
      config.API_URL + `company-admin/supplierManagement/exportSupplyChain`,
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            csvLink: result.data,
          });
          let url = BASE_URL + result.data;
          window.open(url, '_blank');
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  pageChange(e, data) {
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    this.setState({
      skip: pageNo,
    });
    setTimeout(() => {
      this.serverRequest();
    }, 200);
  }

  onDateChange(event) {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: 'application/json',
    };
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    let setStartDate = null;
    let setEndDate = null;
    if (this.state.setStartDate === null) {
      if (name === 'setStartDate') {
        setStartDate = value;
      }
    } else {
      if (value !== this.state.setStartDate && name === 'setStartDate') {
        setStartDate = value;
      } else {
        setStartDate = this.state.setStartDate;
      }
    }

    if (this.state.setEndDate === null) {
      if (name === 'setEndDate') {
        setEndDate = value;
      }
    } else {
      if (value !== this.state.setEndDate && name === 'setEndDate') {
        setEndDate = value;
      } else {
        setEndDate = this.state.setEndDate;
      }
    }
    if (setStartDate !== null && setEndDate !== null) {
      fetch(
        config.OLD_API_URL +
          `supplyChainRiskManagement?limit=${this.state.limit}&skip=${this.state.skip}&startDate=${setStartDate}&endDate=${setEndDate}`,
        { headers }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              totalCount: result.totalCount,
              items: result.data,
              list: result.data,
              pages: Math.floor(result.data.length / this.state.perPage),
              isEditableOrNot: result?.insertOrUpdate,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  async componentDidMount() {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: 'application/json',
    };

    try {
      const supplyChainResponse = await fetch(
        config.OLD_API_URL +
          `supplyChainRiskManagement?limit=${this.state.limit}&skip=${this.state.skip}&startDate=${this.state.startDate}&endDate=${this.state.endDate}`,
        { headers }
      );

      if (!supplyChainResponse.ok) {
        throw new Error('Failed to fetch supply chain data');
      }

      const supplyChainResult = await supplyChainResponse.json();

      this.setState({
        isLoaded: true,
        totalCount: supplyChainResult.totalCount ?? 0,
        items: supplyChainResult.data,
        list: supplyChainResult.data,
        pages: Math.floor(supplyChainResult.data.length / this.state.perPage),
        isEditableOrNot: supplyChainResult.insertOrUpdate,
      });

      // Language Fix
      const cokkie = readCokkie();
      if (cokkie === '/en/da') {
        setTimeout(() => {
          const elem1 = document.querySelector('.lang_fix1');
          if (elem1) {
            elem1.innerHTML = 'BraNchE';
          }
        }, 1000);

        const elems = document.querySelectorAll('.lang_fix2');
        console.log('elems', elems);
        elems.forEach((entity) => {
          entity.innerHTML = 'Se';
        });
      }
    } catch (error) {
      this.setState({
        isLoaded: true,
        error: error.message,
      });
    }
  }

  handlePageClick = (event) => {
    let page = event.selected;
    this.setState({ page });
  };

  serverRequest() {
    const { skip, limit, startDate, endDate } = this.state;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: 'application/json',
    };
    fetch(
      config.OLD_API_URL +
        `supplyChainRiskManagement?limit=${limit}&skip=${skip}&startDate=${startDate}&endDate=${endDate}`,
      { headers }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            totalCount: result.totalCount,
            items: result.data,
            list: result.data,
            pages: Math.floor(result.data.length / this.state.perPage),
            isEditableOrNot: result?.insertOrUpdate,
          });
        },
        (error) => {}
      );
  }

  async handleRequestReport() {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: 'application/json',
    };
    try {
      const response = await axios.post(
        config.BASE_URL + 'api/v1/supplierManagement/generateReport',
        {
          companyName: this.state.businessName,
          countryCode: this.state.countryCode,
        },
        { headers }
      );

      this.setState({ isLoading: true });

      if (response.data.items) {
        this.setState({
          foundCompanies: response.data.items,
          requestReportModal: false,
          companiesDataModal: true,
          isLoading: false,
        });
      }
      return response;
    } catch (error) {
      this.setState({ error: error?.message, isLoading: false });
      console.log(error?.message, error);
    }
  }

  async handleRequestReportStepTwo() {
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: 'application/json',
    };

    if (!this.state.selectedCompany) {
      return;
    }

    const { coriolisCompanyId, companyName, registeredAddress, countryCode } =
      this.state.selectedCompany;

    console.log('call api');
    const response = await axios.post(
      config.API_URL + 'create-checkout-session-test',
      {
        companyId: coriolisCompanyId,
        companyName: companyName,
        registeredAddress: registeredAddress,
        countryCode: countryCode,
      },
      { headers }
    );

    if (response.data.url) {
      this.setState({ companiesDataModal: false });
      window.location.href = response.data.url;
    } else {
      console.log(response, 'response error');
    }

    return response;
  }

  render() {
    const {
      list,
      feeedbackLogs,
      skip,
      currentPage,
      companiesPerPage,
      foundCompanies,
    } = this.state;
    const startIndex = currentPage * companiesPerPage;
    const endIndex = startIndex + companiesPerPage;
    const pageData = foundCompanies.slice(startIndex, endIndex);
    let weathers =
      list.map((item, keyss) => {
        return (
          <tr key={keyss}>
            <th scope="row">{keyss + 1 + skip}</th>
            <td>{item.companyName}</td>
            <td>
              <Moment format="DD/MM/YYYY">{item.createdAt}</Moment>
            </td>
            <td>{item.industry}</td>
            <td>{item.followingCriteria}</td>
            <td>
              <a
                target="_blank"
                rel="noreferrer"
                href={config.BASE_URL + item.response}
              >
                {item.response && (
                  <button className="btn btn-link table-tag" variant="none">
                    <span>
                      <i className="fa fa-eye"></i>
                    </span>
                    <span className="lang_fix2"> View</span>
                  </button>
                )}
              </a>
            </td>
            <td>
              <span className="red bold center">
                {item.identified_risk === 'High' && 'High'}
              </span>
              <span className="green bold center">
                {item.identified_risk === 'Low' && 'Low'}
              </span>
              <span className="bold center">
                {item.identified_risk === 'Medium' && 'Medium'}
              </span>
            </td>
            <td>
              <div className="global_link">
                <button
                  className="btn btn-link table-tag"
                  variant="none"
                  onClick={() => {
                    this.handleOpenModal('login');
                    this.setState({ comments: item.feedback });
                  }}
                >
                  <span>
                    <i className="fa fa-eye"></i>
                  </span>
                  <span className="lang_fix2">View</span>
                </button>
              </div>
            </td>
            <td>
              <div className="global_link" style={{ height: '34px' }}>
                <NavLink
                  to="#"
                  data-id={item.id}
                  user-id={item.userId}
                  onClick={(e) => this.handleOpenModal1(e)}
                >
                  <i
                    data-id={item.id}
                    user-id={item.userId}
                    className="fa fa-info center"
                  ></i>
                </NavLink>
              </div>
            </td>
          </tr>
        );
      }) || '';
    return (
      <div>
        <Sidebar dataFromParent={this.props.location.pathname} />
        <Header />
        {this.state.isLoading && (
          <div className="loader-block">
            <Loader />
          </div>
        )}
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2">
                        <div className="text_Parts">
                          <div className="text_Description">
                            <div className="d-flex justify-content-between mb-3">
                              <h5 className="motor font-heading">
                                Introduction
                              </h5>
                              <div className="form_x mb-3">
                                <div className="input-group"></div>
                              </div>
                            </div>
                            <p>
                              The Supplier ESG Assessment helps you start your
                              journey towards understanding social,
                              environmental and business ethics practices
                              throughout your supply chain.
                            </p>
                            <p>
                              Based on responsible supply chain engagement
                              strategies at leading global businesses, the
                              Supplier ESG Assessment is a proven indicator set
                              that helps you quickly measure supplier
                              performance and identify vast opportunities for
                              supplier improvement.
                            </p>
                            <p>
                              We help you quickly create visibility, access
                              supplier intelligence and accelerate progress
                              toward your goals.
                            </p>
                          </div>
                          <div
                            style={{ display: 'flex', position: 'relative' }}
                            className="directly mt-3 p-0"
                          >
                            <button
                              onClick={this.downloadReport}
                              className="next_page_one down_rep"
                              type="button"
                            >
                              <span className="Download_r">
                                <i className="fa fa-download"></i>
                              </span>
                              Download Supplier Management Report
                            </button>

                            {this.state.isCoriolisRequested && (
                              <button
                                style={{
                                  flexGrow: '1',
                                  maxWidth: '250px',
                                  marginLeft: '10px',
                                }}
                                className="next_page_one"
                                title="The Report will be uploaded within next 48 hours"
                                data-toggle="tooltip"
                                data-placement="top"
                                type="button"
                              >
                                <span className="Download_r">
                                  <i className="fa fa-download"></i>
                                </span>
                                Download Coriolis Report
                              </button>
                            )}

                            {this.state.isEditableOrNot && (
                              <NavLink
                                style={{ marginTop: '20px' }}
                                className="re-submit mx-3"
                                to={'/supplier_management_option'}
                              >
                                Additional Supplier Assessments
                              </NavLink>
                            )}

                            <button
                              onClick={this.handleOpenRequestReportModal}
                              style={{
                                background:
                                  'linear-gradient(94.47deg, #2F2216 1.14%, #7D563A 114.41%)',
                                color: 'white',
                                border: '0px solid #1c5593',
                                marginLeft: 'auto',
                              }}
                              className="next_page_one"
                              type="button"
                            >
                              Request EVO ESG Report
                            </button>
                            <div
                              className="info-box"
                              onMouseOver={() =>
                                this.setState({ showTooltip: true })
                              }
                              onMouseOut={() =>
                                this.setState({ showTooltip: false })
                              }
                            >
                              <div className="info_box-icon">
                                <img src={info} alt="info" />
                              </div>
                              <div
                                className="info_box-content"
                                style={{
                                  display: this.state.showTooltip
                                    ? 'block'
                                    : 'none',
                                }}
                              >
                                <p>
                                  EvoESG is the world’s first automated
                                  environmental, social and governance scoring
                                  solution. This scoring system will give you a
                                  benchmark of your organizations current ESG
                                  performance, enhancing enterprise intelligence
                                  and resilience by measuring key entity data
                                  against globally recognised frameworks, such
                                  as the UN Sustainable Development Goals and
                                  the EU Taxonomy.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2 mt-3 pt-4">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <h5 className="motor font-heading">
                            Here you can find all your requested files
                          </h5>
                          <a href="/requested_reports">
                            <button
                              style={{
                                background:
                                  'linear-gradient(94.47deg, #2F2216 1.14%, #7D563A 114.41%)',
                                color: 'white',
                                border: '0px solid #1c5593',
                                marginLeft: 'auto',
                              }}
                              className="next_page_one"
                              type="button"
                            >
                              Show Reports
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2 mt-3 pt-4">
                        <div className="business_detail">
                          <div className="saved_cards">
                            <div className="business_detail">
                              <div className="heading mb-3">
                                <div className="heading_wth_text">
                                  <h4 className="font-heading">
                                    Supply Chain ESG Risk Management
                                  </h4>
                                </div>
                                <form>
                                  <div className="row m-0">
                                    <div className="col-xxl-4 col-6">
                                      <div className="form-group d-flex">
                                        <input
                                          type="date"
                                          onChange={
                                            (this.handleChange,
                                            this.onDateChange)
                                          }
                                          className="form-control date-picker"
                                          name="setStartDate"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-xxl-4 col-6">
                                      <div className="form-group">
                                        <input
                                          type="date"
                                          onChange={
                                            (this.handleChange,
                                            this.onDateChange)
                                          }
                                          className="form-control date-picker"
                                          name="setEndDate"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-xxl-4 col-12">
                                      <div className="button_download">
                                        {this.state.isEditableOrNot && (
                                          <button
                                            onClick={this.exportCSV}
                                            className="page_save page_width"
                                            style={{ height: '52px' }}
                                          >
                                            Download
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="table_f manage-detail admin-risk-table table-responsive">
                              <Table striped bordered hover size="sm">
                                <thead>
                                  <tr className="heading_color">
                                    <th>ID</th>
                                    <th scope="col">SUPPLIER</th>
                                    <th scope="col">DATE ADDED</th>
                                    <th
                                      scope="col"
                                      className="lang_fix1"
                                      style={{ textTransform: 'uppercase' }}
                                    >
                                      INDUSTRY
                                    </th>
                                    <th scope="col">CRITERIA ASSESSED</th>
                                    <th scope="col">RESPONSE</th>
                                    <th scope="col">IDENTIFIED RISK</th>
                                    <th scope="col">FEEDBACK</th>
                                    <th scope="col">LOGS</th>
                                  </tr>
                                </thead>
                                <tbody>{weathers}</tbody>
                              </Table>

                              <div className="Page navigation example">
                                <Pagination
                                  className="mx-auto pagination"
                                  defaultActivePage={1}
                                  onPageChange={this.pageChange}
                                  ellipsisItem={{
                                    content: (
                                      <Icon name="ellipsis horizontal" />
                                    ),
                                    icon: true,
                                  }}
                                  firstItem={{
                                    content: <Icon name="angle double left" />,
                                    icon: true,
                                  }}
                                  lastItem={{
                                    content: <Icon name="angle double right" />,
                                    icon: true,
                                  }}
                                  prevItem={{
                                    content: <Icon name="angle left" />,
                                    icon: true,
                                  }}
                                  nextItem={{
                                    content: <Icon name="angle right" />,
                                    icon: true,
                                  }}
                                  totalPages={Math.ceil(
                                    this.state.totalCount / this.state.limit
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <Modal
                animation={true}
                size="lg"
                className="modal_box"
                shadow-lg="border"
                show={
                  this.state.showModal && this.state.activeModal === 'login'
                }
              >
                <div>
                  <Modal.Header className="pb3">
                    <Modal.Title>
                      <h4>Feedback</h4>
                    </Modal.Title>
                    <Button
                      variant="outline-dark"
                      onClick={this.handleCloseModal}
                    >
                      <i className="fa fa-times"></i>
                    </Button>
                  </Modal.Header>
                  <div className="modal-body vekp">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="pb3">
                          <div className="feedback_comment">
                            {this.state.comments && this.state.comments}
                            {this.state.comments === null && 'No Comment'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              {this.state.showModal1 && (
                <Modal
                  animation={true}
                  size="md"
                  className="modal_box"
                  shadow-lg="border"
                  show={
                    this.state.showModal1 && this.state.activeModal1 === 'login'
                  }
                >
                  <div className="modal-lg">
                    <Modal.Header className="pb3">
                      <Modal.Title>
                        <h4>Feedback Logs</h4>
                      </Modal.Title>
                      <Button
                        variant="outline-dark"
                        onClick={() => this.handleCloseModal1()}
                      >
                        <i className="fa fa-times"></i>
                      </Button>
                    </Modal.Header>
                    <div className="modal-body vekp pt-0">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="pb3">
                            <form>
                              <div className="mb-3 p-3">
                                <Table striped bordered hover size="sm">
                                  <thead>
                                    <tr className="heading_color">
                                      <th>ID</th>
                                      <th>Feedbacks / Comments</th>
                                      <th>Updated date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {feeedbackLogs.map((item, key) => (
                                      <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>{item.feedback}</td>
                                        <td>
                                          {moment(item.createdAt)
                                            .utc()
                                            .format('DD-MM-YYYY')}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              )}

              {/*  */}

              <Modal
                animation={true}
                size="lg"
                shadow-lg="border"
                show={this.state.requestReportModal}
              >
                <div className="modal_header-logo">
                  l<img src={logo} alt="logo"  style={{width:"120px"}}/>
                </div>
                <div className="modal-report-content">
                  <p className="title">Get your EvoESG score</p>
                  <p className="description">
                    Access the world’s first automated environmental, social and
                    governance scoring solution. It is an accessible,
                    independent and highly effective solution. EvoESG has been
                    developed in collaboration with more than 70 financial
                    institutions, banks and professional bodies. EvoESG,
                    delivered via UI or API, enhances enterprise intelligence
                    and resilience by measuring key entity data against globally
                    recognised frameworks, such as the UN Sustainable
                    Development Goals and the EU Taxonomy.
                  </p>

                  <div className="score-form">
                    <div className="row">
                      <div className="form-group fg">
                        <label className="st_name" htmlFor="name">
                          <b>Business Name</b>
                        </label>
                        <input
                          className="form-control name_nf"
                          type="text"
                          name="businessName"
                          placeholder="Enter business name"
                          value={this.state.businessName}
                          onChange={(e) =>
                            this.setState({ businessName: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-group fg">
                        <label className="st_name" htmlFor="name">
                          <b>Country Code*</b>
                        </label>

                        <select
                          onChange={this.selectCountryCode}
                          className="custom-select"
                        >
                          <option value="">Please choose a country code</option>
                          {this.state.countryCodes.map((item) => {
                            return (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="global_link w-100 form-buttons">
                        <button
                          className="page_width cancel_page w-100"
                          onClick={this.handleCloseRequestReportModal}
                        >
                          Cancel
                        </button>

                        {this.state.businessName ? (
                          <button
                            type="submit"
                            value="Submit"
                            className="page_width page_save w-100"
                            onClick={this.handleRequestReport}
                          >
                            Next
                          </button>
                        ) : (
                          <button
                            type="submit"
                            value="Submit"
                            className="page_width page_save w-100 disabledd"
                            title="Business name is required"
                            data-toggle="tooltip"
                          >
                            Next
                          </button>
                        )}
                      </div>
                    </div>

                    <p className="note">
                      * Note: a EvoESG score can be completed on a specific
                      business address such as a subsidiary, franchise or
                      warehouse, or on a business as a whole
                    </p>
                  </div>
                </div>
              </Modal>

              {/*  */}

              <Modal
                animation={true}
                fullscreen={true}
                show={this.state.companiesDataModal}
                className="my-modal"
              >
                <div className="modal-data-content">
                  <div className="score-form">
                    <div className="row">
                      <div className="companies-data">
                        <button
                          onClick={this.handlePrevPage}
                          disabled={currentPage === 0}
                          className="prev-button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            height="35"
                            fill="currentColor"
                            class="bi bi-arrow-left-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                            />
                          </svg>
                        </button>
                        {pageData.length === 0 ? (
                          <p>Sorry, Companies not found</p>
                        ) : (
                          pageData.map((item, index) => {
                            return (
                              <div className="companies-data-card" key={index}>
                                <div style={{ paddingTop: '5px' }}>
                                  <input
                                    type="radio"
                                    onChange={(e) =>
                                      this.handleRadioChange(e, item)
                                    }
                                  />
                                </div>
                                <div className="company-info">
                                  <p className="caption">{item.companyName}</p>
                                  <p className="text">
                                    Country code: {item.countryCode}
                                  </p>
                                  <p className="text">
                                    Address: {item.registeredAddress}
                                  </p>
                                  {/* <p className="caption">{item.API}</p>
                                  <p className="text">{item.Category}</p> */}
                                </div>
                              </div>
                            );
                          })
                        )}
                        <button
                          onClick={this.handleNextPage}
                          disabled={
                            (currentPage + 1) * companiesPerPage >=
                            foundCompanies.length
                          }
                          className="next-button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            height="35"
                            fill="currentColor"
                            class="bi bi-arrow-right-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                            />
                          </svg>
                        </button>
                      </div>

                      <div className="global_link w-100 form-buttons">
                        <button
                          className="page_width cancel_page w-100"
                          onClick={this.handleCloseCompaniesDataModal}
                        >
                          Cancel*
                        </button>

                        <button
                          type="submit"
                          value="Submit"
                          className="page_width page_save w-100"
                          onClick={this.handleRequestReportStepTwo}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>

                    <p className="note">
                      * If the company you are searching for is not here, please
                      contact us
                    </p>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
