import React, { Component } from "react";
import logo from "../../img/logoNew.png";
import { NavLink } from "react-router-dom";
// import "./login.css";
import "../sidebar/common.css";
export default class ResetMassage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

 
  componentDidMount() {
    
  }
  render() {
    return (
      <div>
        <section className="login">
          <div className="login_part">
            <div className="sing_log">
              <div className="sing_one">
                <img src={logo} alt="logo" style={{width:"120px"}}/>
              </div>
              <div className="text_sing mb-4">
                <p className="faster_oval"></p>
                <h4 className="Account">Reset Your Password</h4>
                <p className="massage">Check your email for a link to Reset your Password. if it doesn't appear within a few minutes, check your Spam folder</p>
                
                  <p>If you know password, then click on Login Button.</p>
                <div className="global d-flex justify-content-center m-3">
                    <NavLink className="page_width page_save button_color" to="/login">
                      Login
                    </NavLink>
                  </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
